@value blue300, grey200 from "../../ui-kit/colors.module.css";
@value tablet, desktop from "../../ui-kit/breakpoints.module.css";

.container {
  padding: 12px;
  padding-left: 0;
  background: white;
  border-bottom: 1px solid #E6E6EC;
  position: sticky;
  top: var(--header-height-tablet);
  z-index: 999;
  transition: all 300ms;
}

.headerHidden {
  top: 0;
}