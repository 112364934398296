@value mobile, tablet, desktop from "../../ui-kit/breakpoints.module.css";

.container {
  background: rgb(242, 242, 247);
}

.main {
  max-width: 1400px;
  margin: 0 auto;
  padding: 16px 0 0;
}

.article {
  background: #ffffff;
  overflow: hidden;
  border-radius: unset;
}

.articleText {
  padding: 27px;
}

.latestArticles > div {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  max-width: 1400px;
  padding: 0;
}

.downloadApp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 27px;
  margin-bottom: 10px;
}

.downloadApp img {
  width: 200px;
}

.downloadAppText {
  margin: 30px 0;
}

.downloadAppText h3 {
  margin-bottom: 20px;
}

.appStoreIcons {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: mobile) {
  .latestArticlesContainer {
    padding: 0 22px;
  }
}

@media screen and (min-width: mobile) {
  .main {
    padding: 96px 45px 0;
  }

  .article {
    border-radius: 24px;
    margin: 0;
  }
}

@media screen and (min-width: tablet) {
  .main {
    padding: 96px 90px 0;
  }

  .latestArticles > div {
    grid-template-columns: 1fr 1fr;
  }

  .downloadApp {
    flex-direction: row;
    margin-bottom: 40px;
  }
  
  .downloadApp img {
    width: 400px;
  }

  .downloadAppText {
    margin: 0 0 0 20px;
  }
}

@media screen and (min-width: desktop) {
  .articleText {
    padding: 60px 110px;
  }

  .latestArticles > div {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .downloadApp {
    padding: 0 110px;
  }  
}
